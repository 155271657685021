import { makeStyles } from '@material-ui/core/styles';
import * as Colors from '@material-ui/core/colors';
import logo from './assets/images/codefront-1280x256.svg';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    background: Colors.indigo[600]
  },
  logo: {
    marginTop: theme.spacing(3),
    maxWidth: 400,
    [theme.breakpoints.up('sm')]: {
      maxWidth: 600
    }
  },
  object: {
    
  }
}))

function App() {

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.object}>
        <img className={classes.logo} src={logo} />
      </div>
      <div className={classes.object}>
        
      </div> 
    </div>
  );
}

export default App;
